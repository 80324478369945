import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import ButtonPlainExternal from "./buttonplain-external.js";

const Footer = () => {
  return (
    <div className="clear-both">
      <div className="block py-12 sm:py-14 md:py-16 lg:py-20 bg-white">
        <div className="container mx-auto px-12 md:px-36 text-center text-gray-900">
          <p>
            <ButtonPlainExternal buttonText="Support" buttonLink="/support" />
          </p>
          <ButtonPlainExternal
            buttonText="Privacy Policy"
            buttonLink="https://www.sixtyfive02.com/privacy/policy"
          />

          <ButtonPlainExternal
            buttonText="Terms of Service"
            buttonLink="https://www.sixtyfive02.com/legal/terms-of-service"
          />
          <p className="my-4">littlelike.me™</p>
          <OutboundLink href="https://sixtyfive02.com">
          <StaticImage height="64" placeholder="non" src="../images/sixtyfive02logo.png" />
            <p className="my-2">© 2025, SixtyFive02, Inc.</p>
          </OutboundLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
